import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { IChat, IChatbotState } from "../types/chatbot";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { getClient } from "../utils/http";
import { encrypt, generateUUID } from "../utils/encryption";
import { getNewChat, sanitizeUserInputQuery } from "../utils/common";
import { selfHelpApplications } from "../utils/constant";
import {
  competitorChatbot,
  competitorSuggestions,
  competitorFeedback,
  competitorGetChatHistory,
} from "./competitor";
import { useAuth } from "./auth_provider";

const datasetName = process.env.REACT_APP_DATASET_NAME;
const perspective = process.env.REACT_APP_PERSPECTIVE;

const CompetitorContext = createContext<any | null>(null);

export function CompetitorProvider({
  children,
  tool,
}: PropsWithChildren<{ tool: string }>) {
  const [selectedChat, setSelectedChat] = useState(0);
  const [selectedQuery, setSelectedQuery] = useState<number | null>(null);
  const [last5Queries, setLast5Queries] = useState<any>([]);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>();
  const [applications, setApplications] = useState(selfHelpApplications);
  const [chats, setChats] = useState<IChat[]>([getNewChat()]);
  const navigate = useNavigate();
  const conversationsContainerRef = useRef<HTMLDivElement>();

  const { userDetails } = useAuth();

  const isChatLoading = useMemo(() => {
    let conversations = chats?.[selectedChat]?.conversations;
    return !!conversations?.[conversations?.length - 1]?.isLoading;
  }, [chats, selectedChat]);

  const scrollDown = () => {
    if (conversationsContainerRef.current) {
      conversationsContainerRef.current.scrollTop =
        conversationsContainerRef.current.scrollHeight;
    }
  };

  const newChat = () => {
    setChats([...chats, getNewChat()]);
    setSelectedChat(chats.length);
  };

  const deleteChat = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.preventDefault();
    event.stopPropagation();
    const filteredChats = chats.filter((chat) => chat.sessionId !== id);
    if (filteredChats.length === 0) {
      filteredChats.push(getNewChat());
    }
    if (selectedChat === chats.length - 1) {
      setSelectedChat(filteredChats.length - 1);
    }
    setChats([...filteredChats]);
  };

  const addConversation = (query: string, locale: string) => {
    setChats((chats) => {
      chats?.[selectedChat]?.conversations?.push({
        query,
        locale,
        role:
          chats?.[selectedChat]?.conversations?.length % 2 === 0
            ? "user"
            : "assistant",
        isLoading: true,
      });

      const updatedChats = [...chats];
      fetchAnswer(
        query,
        updatedChats?.[selectedChat]?.conversations?.length - 1
      );
      return updatedChats;
    });
  };

  const addConversationFromHistory = (data: any) => {
    const request = JSON.parse(data.request);
    const response = JSON.parse(data.response);

    const conversations = chats?.[selectedChat]?.conversations;

    if (conversations?.[conversations?.length - 1]?.query === request?.query) {
      return;
    }

    setChats([
      ...chats,
      {
        sessionId: nanoid(),
        title: "New chat",
        conversations: [
          {
            query: request?.query || request,
            locale: response?.locale || "eng-us",
            isLoading: false,
            sqlQuery: response?.sql_query || "",
            result: response?.result || response,
            isPlottable: false,
            message: response,
            displyType: response?.displyType || "display",
            questionSuggestion: response?.questionSuggestion || [
              "hello",
              "hello",
              "hello",
            ],
            raw: data || response,
            url: response?.insuranceUrl || "",
            comparison: response?.comparison || response,
          },
        ],
      },
    ]);
    setSelectedChat(chats.length);
  };

  const passSuggestion = (suggestion: string) => {
    setSelectedSuggestion(suggestion);
  };

  const like = (chatIndex: number) => {
    if (selectedQuery === null) {
      feedbackSubmit(chatIndex, true);
      return;
    }
    if (selectedQuery !== null) {
      setLast5Queries((queries: any) => {
        queries[selectedQuery].conversations[0].isLiked = true;
        return [...queries];
      });
    } else {
      setChats((chats) => {
        chats[selectedChat].conversations[chatIndex].isLiked = true;
        return [...chats];
      });
    }
  };

  const dislike = (chatIndex: number) => {
    if (selectedQuery === null) {
      feedbackSubmit(chatIndex, false);
      return;
    }
    if (selectedQuery !== null) {
      setLast5Queries((queries: any) => {
        queries[selectedQuery].conversations[0].isLiked = false;
        return [...queries];
      });
    } else {
      setChats((chats) => {
        chats[selectedChat].conversations[chatIndex].isLiked = false;
        return [...chats];
      });
    }
  };

  const feedbackSubmit = async (chatIndex: number, isLiked: boolean) => {
    try {
      const selectedConversation = chats[selectedChat].conversations[chatIndex];

      const payload: any = {
        // userid : `${chatIndex}${chats[selectedChat].sessionId}${chatIndex}`,
        // sessionid : chats[selectedChat].sessionId,
        questionId: chatIndex + 1,
        request: chats[selectedChat].conversations[chatIndex]?.query,
        feedBack: isLiked,
        response: {
          result: chats[selectedChat].conversations[chatIndex]?.result,
        },
        displayType: "summary",
      };

      const res: { result: any; comparison?: any } = {
        result: selectedConversation.result,
      };
      const comparison = selectedConversation?.comparison;
      if (comparison && comparison.length > 0) {
        res.comparison = comparison;
      }
      payload.response = res;

      await competitorFeedback(payload);

      setChats((chats) => {
        selectedConversation.isLiked = isLiked;
        return [...chats];
      });
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAnswer = async (query: string, conversationIndex: number) => {
    try {
      const isValidPrompt = sanitizeUserInputQuery(query);
      if (!isValidPrompt) throw new Error("Invalid Prompt");

      let conversations: any = [];

      chats[selectedChat].conversations?.forEach((item) => {
        if (item.result) {
          conversations.push({ role: "user", content: item.query });
          conversations.push({
            role: "assistant",
            content: item.result as string,
          });
        }
      });

      const payload: any = {
        query: query,
        conversation: [...conversations, { role: "user", content: query }],
        userId: Number(userDetails?.userId),
      };

      //   const formattedPayload = { data: encrypt(JSON.stringify(payload)) };
      const response = await competitorChatbot(payload);
      //   const data = JSON.parse(decrypt(response.data.data));

      if (response.success === 1) {
        setChats((chats) => {
          const conversation =
            chats?.[selectedChat]?.conversations?.[conversationIndex];
          if (conversation) {
            const updatedConversation = {
              ...conversation,
              isLoading: false,
              result: response.response,
              isPlottable: false,
              message: response.response,
            };
            const updatedChats = [...chats];
            updatedChats[selectedChat].conversations[conversationIndex] =
              updatedConversation;
            return updatedChats;
          }

          return chats;
        });
      }
      fetchSuggestions();
    } catch (err) {}
  };

  // const fetchChatHistory = useCallback(() => {
  //   let payload = {
  //     data: encrypt(
  //       JSON.stringify({
  //         serviceName:
  //           tool === "document"
  //             ? "Document Analyzer"
  //             : tool === "help"
  //             ? "Help Bot"
  //             : "",
  //       })
  //     ),
  //   };
  //   getClient(tool)
  //     .post("/get_chat_history", payload)
  //     .then(({ data: { data } }) => {
  //       let chatHistory = JSON.parse(decrypt(data))?.data?.chat_history || [];
  //       setLast5Queries([...chatHistory]);
  //     })
  //     .catch((err) => {
  //       setLast5Queries([]);
  //     });
  // }, [tool]);

  const fetchChatHistory = useCallback(async () => {
    const payload = {
      service_name: "Competitor Analysis",
      userID: Number(userDetails?.userId),
    };
    try {
      const response = await competitorGetChatHistory(payload);
      console.log(response);
      if (response.success === 1) {
        const queryItem: any[] = [];
        response?.response?.chat_history?.slice(0, 5).forEach((item: any) => {
          try {
            JSON.parse(item.request);
            JSON.parse(item.response);
            queryItem.push({ request: item.request, response: item.response });
          } catch (err) {
            const req = JSON.stringify({
              query: item.request,
              questionId: generateUUID(),
              translationLanguage: "en-US",
              userConversation: [],
              datasetName: "insurance_company",
              perspective: "HDFC-ergo",
            });
            const res = JSON.stringify({
              message: [],
              is_plottable: false,
              user_query: item.request,
              result: item.response,
              sql_query: "",
              displyType: "",
              questionSuggestion: [],
              similarityScore: 0,
              textSearchResult: [],
            });

            queryItem.push({ request: req, response: res });
          }
        });

        setLast5Queries(queryItem);
      }
    } catch (error) {
      console.log(error);
    }
  }, [chats]);

  const promptQueryFromHistory = async (queryObj: any, index: number) => {
    try {
      navigate("/company");

      const hasResult = last5Queries?.[index || 0]?.conversations?.[0]?.result;
      if (hasResult) {
        setSelectedQuery(index);
        return;
      }
      setLast5Queries((queries: any) => {
        queries[index].conversations[0] = {
          query: queryObj.query,
          locale: "en-US",
          isLoading: true,
        };
        return [...queries];
      });

      setTimeout(async () => {
        setSelectedQuery(index);
        let payload: any = {
          query: queryObj.query,
          questionId: 1,
          translationLanguage: "en-US",
          userConversation: [],
          lastSql: "",
          datasetName: datasetName,
          perspective: perspective,
        };

        const formattedPayload = {
          data: encrypt(JSON.stringify(payload)),
        };

        const response = await getClient(tool).post(
          "/analysis",
          formattedPayload
          // { headers: { sessionId: chats[selectedChat].sessionId } }
        );

        // const data = JSON.parse(decrypt(response.data));
        const data = response.data;

        setLast5Queries((queries: any) => {
          const conversation = queries?.[index || 0]?.conversations?.[0];

          if (conversation) {
            const updatedConversation = {
              ...conversation,
              isLoading: false,
              sqlQuery: data.sql_query,
              result: data.result,
              isPlottable: data.is_plottable,
              message: data.message,
              displyType: data.displyType,
              questionSuggestion: data.questionSuggestion,
              raw: data,
              url: data.insuranceUrl,
              comparison: data.comparison,
            };

            const updatedChats = [...chats];
            updatedChats[selectedChat].conversations[index] =
              updatedConversation;
            return updatedChats;
          }
          return queries;
        });
      }, 100);
    } catch (err) {
      const conversation = last5Queries?.[index || 0]?.conversations?.[0];
      if (conversation) {
        conversation.isLoading = false;
        conversation.isPlottable = false;
      }
    }
  };

  const updateSuggestions = useCallback((values: string[]) => {
    setSuggestions(values);
  }, []);

  // const fetchSuggestions = useCallback(async () => {
  //   try {
  //     const payload = { datasetName, perspective };
  //     setSuggestions([]);
  //     const response = await getClient(tool).post("/suggestion", payload);
  //     const data = JSON.parse(decrypt(response.data.data));
  //     setSuggestions(data || []);
  //   } catch (err) {
  //     setSuggestions([]);
  //   }
  // }, [tool]);

  const fetchSuggestions = useCallback(async () => {
    const conversations = chats[0]?.conversations;
    const lastQuery = conversations?.length
      ? conversations[conversations.length - 1].query
      : "tell me something about HDFC ergo";
    const payload = {
      query: lastQuery,
    };
    try {
      const response = await competitorSuggestions(payload);
      if (response.success === 1) {
        setSuggestions(response.response || []);
      }
    } catch (error) {
      console.log(error);
    }
  }, [chats]);

  useEffect(() => {
    scrollDown();
  }, [selectedChat, isChatLoading]);

  useEffect(() => {
    fetchSuggestions();
    fetchChatHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CompetitorContext.Provider
      value={{
        selectedQuery,
        setSelectedQuery,
        last5Queries,
        promptQueryFromHistory,
        selectedChat,
        setSelectedChat,
        passSuggestion,
        chats,
        selectedSuggestion,
        newChat,
        deleteChat,
        addConversation,
        addConversationFromHistory,
        conversationsContainerRef,
        suggestions,
        updateSuggestions,
        like,
        dislike,
        isChatLoading,
        applications,
        setApplications,
      }}
    >
      {children}
    </CompetitorContext.Provider>
  );
}

export default function useCompetitor(): IChatbotState {
  let context = useContext(CompetitorContext);
  return context!;
}
